<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["graph_data"],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Line Chart",
            data: [],
            fill: false,
            borderColor: "#D30024",
            backgroundColor: "#D30024",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 100,
                min: 0,
                max: 500,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    console.log(this.options.scales.yAxes[0].ticks, "options");
    this.chartData.labels = this.graph_data.label;
    // this.options.scales.yAxes[0].ticks.max =this.graph_data.goal
    this.options.scales.yAxes[0].ticks.max = this.graph_data.graph_value;
    this.options.scales.yAxes[0].ticks.stepSize = this.graph_data.step;
    console.log(this.graph_data, "graph_data");
    this.chartData.datasets[0].data = this.graph_data.value;
    this.renderChart(this.chartData, this.options);
  },
};
</script>
